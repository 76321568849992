import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext, ConstantsContext } from "../index";
import SignUp from "./SignUp";
import DartsDesign from "./DartsDesign";
import DartsDesign2 from "./DartsDesign2";


import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';


import '../App.css';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import FormReferAFriend from "./FormReferAFriend";
import SelectLabels from "./SelectLabels";
import DashboardTopMenu from "./DashboardTopMenu";


import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { useHistory } from "react-router-dom";

import NativeSelect from '@mui/material/NativeSelect';
import DartsDesign2Historic from "./DartsDesign2Historic";

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import PnlListView from "./PnlListView";
import WatchListTable from "./WatchListTable";


import moment from 'moment'
import ControlledSwitches from './ControlledSwitches';


let local_currentHistoricData = 'current';
const useStyles = makeStyles((theme3: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    copyright: {
      padding: theme3.spacing(2,2),
      alignItems: 'center',
      flex: 1,
      justifyContent: 'center'
    },
    nested: {
      paddingLeft: theme3.spacing(1),
    },

    outerDiv: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'space-between'

    },
    leftDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    },
    rightDiv: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1
    }
  }),
);

let myPortfolioDoneAndUndone = [];

const MultiSelect = (props) => {
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleCheckboxChange = (userId) => {
    const newSelectedUsers = [...selectedUsers];
    if (newSelectedUsers.includes(userId)) {
      newSelectedUsers.splice(newSelectedUsers.indexOf(userId), 1);
    } else {
      newSelectedUsers.push(userId);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const handleApplyButtonClick = () => {
    console.log(selectedUsers.join(','));
    props.setSelectedUsers(selectedUsers.join(','));
    props.analyseUsersFilterApply(selectedUsers.join(','))
  };

  return (
    <div>
      {props.UserList.map((user) => (
        <div key={user.user_id}>
          <label>
            <input
              type="checkbox"
              value={user.user_id}
              checked={selectedUsers.includes(user.user_id)}
              onChange={() => handleCheckboxChange(user.user_id)}
            />
            {user.username}
          </label>
        </div>
      ))}
      <button onClick={handleApplyButtonClick}>Apply</button>
    </div>
  );
};


export default function RouteDashboardConsumer(props) {

  const [theme, toggleTheme, activeTheme, dartCurrentHistoricalDate, setDartCurrentHistoricalDateFnc, currentDartDate, setCurrentDartDateFnc] = useContext(ThemeContext);
  const [staticText] = useContext(ConstantsContext);

  let intervalId = 0; 

  const classes = useStyles();
  const theme3 = useTheme();

  

  

  const [randomKeyUpdate, setRandomKeyUpdate] = useState(0);
  const [myPortfolioListArr, setMyPortfolioListArr] = useState([]);

  const [coin_pair_ids, setCoin_pair_ids] = useState('');

  const [isSuperUser, setIsSuperUser] = useState(false)
  const [watchListArr, setWatchListArr] = useState([]);
  const [analyseUsersArr, setAnalyseUsersArr] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState('');
  

  const [showOnlySupportResistance, setShowOnlySupportResistance] = useState(false)

  const _showOnlySupportResistanceFnc = (_id, _only) => {
    setShowOnlySupportResistance(_only)
    console.log('filter coins = ', _only ? 'checked only' : 'show all')

    let _watchListArr = watchListArr
    if(_only) {

      _watchListArr = watchListArr.filter(item => item.is_support || item.is_resistance);
      setWatchListArr(_watchListArr)
    } else {

      _api_get_portfolio()
    }
  }
  

  

  const [currentHistoricData, setCurrentHistoricData] = useState(dartCurrentHistoricalDate);

  const [dateValue, setDateValue] = React.useState(currentDartDate);

  const handleDateChange = (newValue) => {
    setDateValue(dayjs(newValue).format('YYYY-MM-DD'));
    setCurrentDartDateFnc(dayjs(newValue).format('YYYY-MM-DD'))
    console.log('newValue = ', newValue, 'handleDateChange newValue = ', dayjs(newValue).format('YYYY-MM-DD'))
    
  };

  //const [myPortfolioDoneAndUndone, setMyPortfolioDoneAndUndone] = useState([]);

  ////////////////////////////////////////_api_get_portfolio
  function _api_get_portfolio_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_portfolio error = ' + _data.msg);
      //console.log('get_portfolio code = ' + _data.status_code);
      if(_data.status_code == 4 || _data.status_code == 8) {
        changeHistory('/Dashboard', '/BuildPortfolio')
      }
      
    } else {
      
      //console.log('get_portfolio email was successfully sent');

      //_data.data.sort((a, b) => a.left_side > b.left_side ? 1 : -1);


        ////////////////////////////////////////////
        ////////////////////////////////////////////
        let coin_pair_idsArr = [];
        let coin_pair_idsStr = '';

        _data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
        myPortfolioDoneAndUndone = _data.data;

        _data.data.map((value, key) => {

            //coin_pair_ids = coin_pair_ids + 
            coin_pair_idsArr.push(value.id);
        })
        coin_pair_idsStr = coin_pair_idsArr.toString();
        setCoin_pair_ids(coin_pair_idsStr)
        //console.log('coin_pair_idsStr = ' + coin_pair_idsStr)

        console.log('interval local_currentHistoricData = ', dartCurrentHistoricalDate)
        if(props.userDetails.usertype == 0) {
            _api_get_analyse_average_for_coin_ids(coin_pair_idsStr, dartCurrentHistoricalDate);
        } else {
            _api_get_analyse_for_coin_ids(coin_pair_idsStr);
            //_api_get_analyse(props.coinPairId)
        }

        console.log('props.userDetails = ', props.userDetails)
        console.log('props.userDetails.user_id = ', props.userDetails.user_id)

        if(props.userDetails.user_id == 2056 || props.userDetails.user_id == 84) {

          setIsSuperUser(true)
        } else {

          setIsSuperUser(false)
        }

      //console.log('svsvsvsv = ' + _data.data.length)
      //////////////CHANGED 2023_08_03, uncomment please if something does not work well
      /*if(_data.data.length == 0) {
        changeHistory('/Dashboard', '/BuildPortfolio')
      }*/
      ///////////////////////////////////////////////////////////////////////
    }
    
  }

  const _api_get_portfolio = () => {

    setRandomKeyUpdate(Math.random())
    let data = [
      {'key':'action', 'value': 'get_portfolio'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_get_portfolio_callback)
  }
  //////////////////////////////////////

  ////////////////////////////////////////_api_get_analyse_average_for_coin_ids
  function _api_get_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code); 
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      //console.log('get_analyse_average_for_coin_ids email was successfully sent');

      //_data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
      setMyPortfolioListArr(_data.data)

      //console.log('get_analyse_average_for_coin_ids = ' + _data.data)

      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_analyse_users_callback(_data) {

    if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      setAnalyseUsersArr(_data.data)
      console.log('_api_analyse_users_callback _data.data = ' + _data.data)
      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_analyse_users() {

    let data = [
      {'key':'action', 'value': 'analyse_users'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token}
    ]

    props._apiConnect(staticText.path_darts, data, _api_analyse_users_callback)
  }
  
  function _api_watchlist_callback(_data) {

    if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      setWatchListArr(_data.data)
      console.log('_api_watchlist_callback _data.data = ' + _data.data)

      ///////////get analyse_users
      _api_analyse_users()
      ///////////////////////////////////////////////////////////////////////
    }
  }

  function _api_get_historical_analyse_average_for_coin_ids_callback(_data) {

    if(_data.status_code != 2) {
      
      //console.log('get_analyse_average_for_coin_ids error = ' + _data.msg);
      //console.log('get_analyse_average_for_coin_ids code = ' + _data.status_code); 
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      }     
    } else {
      
      console.log('_api_get_historical_analyse_average_for_coin_ids_callback')
      //console.log('get_analyse_average_for_coin_ids email was successfully sent');

      //_data.data.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);

      setMyPortfolioListArr(_data.data)
      console.log(JSON.stringify(_data.data))

      //console.log('get_analyse_average_for_coin_ids = ' + _data.data)


      ///////////////////////////////////////////////////////////////////////
    }
  }

  /////////////////////////////////////////////////////////////////////////////

  

  const _api_get_analyse_average_for_coin_ids = (_coin_pair_ids, _currentHistoricData) => {

    console.log('_api_get_analyse_average_for_coin_ids _currentHistoricData = ', _currentHistoricData)
    setRandomKeyUpdate(Math.random())

    let data = [];

    if(_currentHistoricData == 'current') {

      data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    } 
    else if(_currentHistoricData == 'current_date') {

      data = [
        {'key':'action', 'value': 'get_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids},
        {'key':'as_of_day', 'value': currentDartDate}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_analyse_average_for_coin_ids_callback)
    }
    else if(_currentHistoricData == 'watchlist') {

      _api_watchlist(_coin_pair_ids, '')
    }
    else {

      data = [
        {'key':'action', 'value': 'get_historical_analyse_average_for_coin_ids'},
        {'key':'loggined_token', 'value': props.userDetails.loggined_token},
        {'key':'coin_pair_ids', 'value': _coin_pair_ids}
      ]

      props._apiConnect(staticText.path_darts, data, _api_get_historical_analyse_average_for_coin_ids_callback)
    }
  }

  const analyseUsersFilterApply = (_analyse_users) => {

    setShowOnlySupportResistance(false)
    _api_watchlist(coin_pair_ids, _analyse_users)
  }


  const _api_watchlist = (_coin_pair_ids, _analyse_users) => {

    let data = [
      {'key':'action', 'value': 'watchlist'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'coin_pair_ids', 'value': _coin_pair_ids},
      {'key':'analyse_users', 'value': _analyse_users}
    ]

    props._apiConnect(staticText.path_darts, data, _api_watchlist_callback)
  }

  /////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////_api_get_analyse_for_coin_ids
  function _api_get_analyse_for_coin_ids_callback(_data) {

    //if(_data.status_code != 2) {
      
      if(_data.status_code == 101) {
        changeHistory('/Dashboard', '/MySubscriptions')
      } else {

        let coin_pair_idsArr = [];
        //console.log('length = ' + myPortfolioDoneAndUndone.length);
  
        let isAdded = false;
        if(myPortfolioDoneAndUndone != undefined) { 
          myPortfolioDoneAndUndone.map((value, key) => {
  
            isAdded = false;
            //console.log(value.id);
            if(_data.data != undefined) {
              _data.data.map((_value, _key) => {
  
                if(value.id == _value.coin_pair_id) {
  
                    isAdded = true;
                    coin_pair_idsArr.push(_value);
                }
              })
            }
            if(!isAdded) {
  
                coin_pair_idsArr.push({'coin_pair_id':value.id, 'coin_pair_name':value.left_side + '-' + value.right_side, '_1h':'-100', '_4h':'-100', '_1d':'-100', '_1w':'-100', '_1m':'-100'});
            }
          })
        }
  
        //coin_pair_idsArr.sort((a, b) => a.coin_pair_name > b.coin_pair_name ? 1 : -1);
        
        setMyPortfolioListArr(coin_pair_idsArr)
        
  
        //console.log('get_analyse_for_coin_ids = ' + _data.data)
  
  
        ///////////////////////////////////////////////////////////////////////
      }

      
    //}
    
  }

  const _api_get_analyse_for_coin_ids = (_coin_pair_ids) => {

    //console.log('_coin_pair_ids = ' + _coin_pair_ids)
    setRandomKeyUpdate(Math.random())
    let data = [
      {'key':'action', 'value': 'get_analyse_for_coin_ids'},
      {'key':'loggined_token', 'value': props.userDetails.loggined_token},
      {'key':'coin_pair_ids', 'value': _coin_pair_ids}
    ]

    props._apiConnect(props._path, data, _api_get_analyse_for_coin_ids_callback)
  }
  //////////////////////////////////////

  const chooseCurrentHistoryFncSelect = (e) => {

    console.log('chooseCurrentHistoryFncSelect = ', e.target.value)

    setCurrentHistoricData(e.target.value);
    local_currentHistoricData = e.target.value;
    setDartCurrentHistoricalDateFnc(e.target.value)
    
    //if(e.target.value == 'current') {


      _api_get_analyse_average_for_coin_ids(coin_pair_ids, e.target.value)
    //} else {

      //_api_get_historical_analyse_average_for_coin_ids(coinPairId, e.target.value)
    //}
    
    //chooseCoinPairFnc(e.target.value)
  }
  

  const history = useHistory();

  const changeHistory = (_from, _to) => {
    history.push(_to, { from: _from })
  };

  useEffect(() => {

    if(!props.userDetails.user_loged_in) {
  
      changeHistory('/Dashboard', '/')
     
    } else {

      if(props._path != undefined) {

        _api_get_portfolio()

        intervalId = setInterval(() => {
          _api_get_portfolio()
        }, 300000); //300000

        return () => clearInterval(intervalId);


      } else {
        
      }
    }
  }, []);

  useEffect(() => {

  }, [dartCurrentHistoricalDate]);

  return (
    <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
      <DashboardTopMenu isDashboard="yes" _apiLogOff={props._apiLogOff} userDetails={props.userDetails} />
      <>
        <Grid item xs={12} sm={12} md={12} lg={4} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%', maxWidth:400}} direction="row" justifyContent="center" alignItems="center" >
                <Grid style={{zIndex:1}} item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <div>
                    <PnlListView userDetails={props.userDetails} _apiConnect={props._apiConnect} />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} columns={12} align="center" justify="center" style={{height: '100%', width:'100%'}} direction="row" justifyContent="center" alignItems="center" >
                <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <div style={{paddingTop:20, textAlign:'left'}}>
                    <div style={{height:20}} />
                      <b style={{fontSize:20, color: '#FF4B4B'}}>New rules of engagement coming effective March 1, 2025.</b>
                      <br />
                      <div style={{fontSize:16, color: '#FF4B4B'}}>
                      Fee based participation will replace current engagement rules. No more mandatory analysis will be required. Analysts will be able to operate all crypto coins covered by CMD. Spread the word to make it more interesting and rewarding.
                      </div>
                      <div style={{height:20}} />
                      <b>Virtual Portfolio Competition Announcement</b>
                      <br />
                      We are thrilled to invite you to participate in our monthly Virtual Portfolio Crypto Trading Competition. As a passionate analyst or trader like yourself, we believe you have the skills and analytical prowess to excel in this thrilling challenge.
                      <div style={{height:20}} />
                      <b>About the Competition:</b>
                      <br />
                      The competition kicks off on the first of each month and runs until the last day of the same month. The goal is to showcase your exceptional analytical and trading skills while maneuvering the dynamic world of cryptocurrency markets.
                      <div style={{height:20}} />
                      <b>Rewards and Recognition:</b>
                      <br />
                      To make things even more exciting, we have enticing rewards for the top-performing analysts. On the last day of the month, at midnight (EST time zone), the <b>top ten</b> analysts will be awarded cash prize of $200 each (paid in BTC or SOL equivalents) provided they meet the following conditions:
                      <br />
                      <b>1. If you didn't do so already, register as a Contributor - this will enable your ability to run a virtual trading portfolio.</b>
                      <br />
                      <br />
                      2. Pay USD $20 participation fee in (in the form of SOL sent to our wallet <b>E8A19ZA2b2ojpAM5GiS1cnC9pBygiEFB5wjxSg5AucuQ</b>) and email us with receipt of your payment to <a href='mailto:info@cryptomarketdarts.com'>info@cryptomarketdarts.com</a> . You no longer need to contribute research and you can virtually trade any coins we support. Once we receive the payment, we will enable your monthly participation. You can join at any time during the month.
                      <br />
                      <br />
                      3. Your portfolio outperformed BTC price <b>by at least 10%, when BTC is greater than negative 9% MTD</b> (for example, BTC price MTD is down 4% MTD but your portfolio is up by 6% or more MTD)
                      <br />
                      <br />
                      4. Your portfolio is at least <b>1% up when BTC price is less than negative 10% MTD</b> (for example, BTC price MTD is down 15% but your portfolio is up 1% or more MTD)
                      <br /><br />
                      But, of course, the real triumph lies in the recognition and respect you'll earn within our vibrant analyst community
                      <div style={{height:20}} />
                      <b>How to Participate:</b>
                      <br />
                      Participating in the competition is a breeze. Here's a simple step-by-step guide:
                      <br />
                      <br />
                      {props.userDetails.usertype == 1 ? 
                        <>
                          Create your Analyst Account on <a href="https://cryptomarketdarts.com" style={{color:theme.btn_color}}>www.cryptomarketdarts.com</a>.
                          <br />
                          Make sure you start contributing your research at least once every <b>two (2)</b> days (ideally every day). Exceptions apply to automated programming and select proprietary accounts not rewarded during competition regardless of their results.
                          <br />
                        </>
                      :
                        null
                      }
                      
                      Click the blue portfolio button (top right corner).
                      <br />
                      {props.userDetails.usertype == 1 ? 
                        <>
                        Access a virtual cash loan of $1,000,000 to kickstart your crypto trading journey.
                        </>
                      :
                        null
                      }
                      <div style={{height:20}} />
                      <b>Learn, Compete, and Have Fun:</b>
                      <br />
                      While the competition is fierce, it's also an excellent opportunity to learn from fellow enthusiasts, enhance your trading strategies, and enjoy the thrill of the crypto market. Win or lose, every experience gained will contribute to your growth as an analyst and trader.
                      <div style={{height:20}} />
                      <b>Join Us Today!</b>
                      <br />
                      Don't miss out on the excitement and camaraderie of our Virtual Portfolio Crypto Trading Competition.
                  </div>
                  
                </Grid>
              </Grid>
                  
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} align="center" justify="center">
          <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="flex-start" style={{width:'100%', margin: 0}} >
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:900, paddingBottom:10, backgroundColor:theme.infoPanelBackground, borderRadius: 10}} >
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                  <div style={{fontSize:20}}>
                    
                    {props.userDetails.usertype == 0 ? 
                      'Averaged Trend Darts'
                    :
                      props.userDetails.username + '\'s Trend Darts'
                    }
                    {dartCurrentHistoricalDate}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} align="center" justify="center">
                  <div style={{display: 'flex', alignItems: 'center', width:360, height:20}}>
                  
                    <SelectLabels color="#00B050" name="Bullish - Strong" />
                    <SelectLabels color="#25FF88" name="Bullish" />
                    <SelectLabels color="#808080" name="Neutral" />
                    <SelectLabels color="#FFA6A0" name="Bearish" />
                    <SelectLabels color="#FF0000" name="Bearish - Strong" />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {props.userDetails.usertype == 0 ? 
              <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
                  <div style={{alignItems: 'center', maxWidth:800, backgroundColor:theme.infoPanelBackground, paddingTop:20}}>
                      <NativeSelect
                        defaultValue={dartCurrentHistoricalDate}
                        inputProps={{
                          name: 'current_history_select_name',
                          id: 'current_history_select',
                        }}
                        value={dartCurrentHistoricalDate}
                        style={{width:'100%', paddingLeft:10, backgroundColor:'#FFFFFF'}}
                        onChange={chooseCurrentHistoryFncSelect}
                      >
                        <option key="current" value="current">Current</option>
                        <option key="current_historic_data" value="current_historic_data">Current + Historic Data</option>
                        <option key="current_date" value="current_date">As of Date</option>
                        {
                        isSuperUser ? 
                          <option key="watchlist" value="watchlist">Watch List</option>
                        :
                          null
                        }
                      </NativeSelect>
                  </div>
                  {dartCurrentHistoricalDate == 'current_date' ? 
                    <div style={{alignItems: 'center', maxWidth:800, backgroundColor:'#FFFFFF', paddingTop:10}}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Stack spacing={3}>
                            <DesktopDatePicker
                              minDate={dayjs(new Date()).subtract(12, 'month')}
                              maxDate={dayjs(new Date()).subtract(3, 'day')}
                              label="Date"
                              inputFormat="YYYY-MM-DD"
                              value={dateValue}
                              onChange={handleDateChange}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Stack>
                        </LocalizationProvider>
                    </div>
                  :
                    null
                  }
              </Grid>
            :
              null
            }
            
            <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
              <Grid container spacing={2} rowSpacing={2} columns={12} direction="row" justifyContent="space-between" alignItems="center" style={{width:'100%', maxWidth:props.userDetails.usertype == 0 ? 850 : 1200}} >
              {
                currentHistoricData == 'watchlist' ? 
                  <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center" style={{width:'100%', maxWidth:1200}}>
                    <div style={{paddingTop:20, paddingBottom:10}}>Watchlist</div>
                    <div>
                      Show Support/Resistance Only<ControlledSwitches checked={showOnlySupportResistance ? true : false} id={null} onSwitchChange={_showOnlySupportResistanceFnc} />
                    </div>
                    <div style={{paddingBottom:10}}>
                      IDs ({selectedUsers}) Choose Analytic 
                      {analyseUsersArr.length > 0 ? 
                        <MultiSelect analyseUsersFilterApply={analyseUsersFilterApply} setSelectedUsers={setSelectedUsers} UserList={analyseUsersArr} /> 
                      : 
                        null
                      }
                    </div>
                    <div style={{paddingBottom:10}}>
                        <Alert severity="info">If no checkbox is selected, the watchlist will include all coins.</Alert>
                    </div>
                    <WatchListTable setCurrentHistoricData={setCurrentHistoricData} watchListArr={watchListArr} userDetails={props.userDetails} _apiConnect={props._apiConnect} />
                  </Grid>
                :
                  myPortfolioListArr.length > 0 ?
                    currentHistoricData == 'current' || currentHistoricData == 'current_date' ? 
                      myPortfolioListArr.map((value, key) => {

                        return (

                          <Grid key={key} item xs={12} sm={6} md={4} lg={4} align="center" justify="center">
                            <div onClick={() => changeHistory('/', (props.userDetails.usertype == 0 ? '/DashboardCoin/' + value["coin_pair_id"] + '/' + (value["coin_pair_name"]) : '/DashboardCoinEdit/' + value["coin_pair_id"]))}>

                                <DartsDesign2 
                                    key={value["coin_pair_id"] + '_' + randomKeyUpdate} 
                                    coinPairId={value["coin_pair_id"]} 
                                    name={(value["coin_pair_name"])} 
                                    userDetails={props.userDetails}
                                    _values={value}
                                />
                            </div>
                          </Grid>
                          
                        );
                      })
                    :
                      myPortfolioListArr != undefined ?
                          myPortfolioListArr.map((value, key) => (


                            value[0] != undefined ? 

                                <Grid key={key} item xs={12} sm={6} md={4} lg={4} align="center" justify="center" style={{width:300}}>
                                  <div onClick={() => changeHistory('/', (props.userDetails.usertype == 0 ? '/DashboardCoin/' + value[0]["coin_pair_id"] + '/' + (value[0]["coin_pair_name"]) : '/DashboardCoinEdit/' + value[0]["coin_pair_id"]))}>

                                      <DartsDesign2Historic 
                                          key={value["coin_pair_id"] + '_' + randomKeyUpdate} 
                                          coinPairId={value["coin_pair_id"]} 
                                          name={(value["coin_pair_name"])} 
                                          userDetails={props.userDetails}
                                          _values={value}
                                      />
                                  </div>
                                </Grid>

                            :
                              null
                          ))
                      :
                          null
                  :
                    null
              }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{height:30}}></div>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} align="center" justify="center">
            <div style={{fontSize:10, padding:20, textAlign:'left'}}>
                DISCLAIMER: The material presented on CryptoMarketDarts.com platform has no regard to any specific investment objectives, financial situation or needs of 
                any of its users. This information is presented solely for informational purposes and is not to be construed as a recommendation, solicitation or 
                an offer to buy or sell (long or short) any asset classes covered by CryptoMarketDarts.com platform. None of the content on this platform should 
                be taken as investment advice. CryptoMarketDarts.com or its subsidiary or holding entities or any of the contributing analysts or users should not 
                be seen, treated or otherwise assumed to be investment or financial advisors. The views and opinion expressed in the platform are speculative 
                and do not guarantee any specific result or profit. Trading and investing is extremely high risk and can result in the loss of all of your capital. Any 
                opinions expressed in this platform are subject to change without notice. CryptoMarketDarts.com is not under any obligation to update or keep 
                current the information contained herein. CryptoMarketDarts.com or its subsidiary or holding entities may have an interest in the securities, 
                commodities, cryptocurrencies and or derivatives of any entities referred to in this platform. CryptoMarketDarts.com or its subsidiary or holding 
                entities accept no liability whatsoever for any loss or damage of any kind arising out of the use of all or any part of materials presented on 
                CryptoMarketDarts.com. CryptoMarketDarts.com recommends that you consult with a licensed and qualified professional before making any 
                investment or trading decisions.
            </div>
        </Grid>
      </>
    </Grid>
  );
}